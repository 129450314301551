import React from 'react';
import './BlocklyEditor.css';
import Blockly from 'node-blockly/browser';
import BlocklyDrawer, {Block, Category} from 'react-blockly-drawer';
import {blocks} from './BlockDefinitions.js';
import {evaluateCode} from '../../js/evaluate';
import {onIdentifyModule} from "../../js/websocket";

//Color of the Blocks
Blockly.HSV_SATURATION = 0.6;
Blockly.HSV_VALUE = 0.8;

class BlocklyEditor extends React.Component {

    constructor(props) {
        super(props);

        onIdentifyModule(json => {
            console.log(json);

            this.workspace = Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(Blockly.mainWorkspace)) // Save workspace
            if (JSON.stringify(json) !== JSON.stringify(this.state.modules)) { // If modules received are different than current ones
                this.setState({modules: json});

                this.restoreWorkspace(this.workspace) // Restore previous workspace if there is something to restore
            }
        });
    }

    workspace = '';
    code = '';
    state = {modules: [{}]};
    interval = null;

    restoreWorkspace = (source) => {
        let xml = Blockly.Xml.textToDom(source);
        Blockly.mainWorkspace.clear();
        Blockly.Xml.domToWorkspace(xml, Blockly.mainWorkspace);
    }

    showCode = () => {
        alert(this.code);
    };

    setWorkspaceAndCode = (c, w) => {
        this.workspace = w;
        this.code = c;
        if (this.props.setWorkspace) {
            this.props.setWorkspace(this.code, this.workspace, this.props.tab);
        }
    };

    downloadCode = () => {
        const text = Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(Blockly.mainWorkspace)),
            blob = new Blob([text], {type: 'text/plain'}),
            anchor = document.createElement('a');

        anchor.download = 'little-endian' + Date.now() + '.xml';
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
        anchor.click();
    };

    reloadCode = () => {
        const filePath = document.getElementById('inputFileUpload').files[0];
        if (!filePath) return;
        let reader = new FileReader();
        reader.readAsText(filePath);
        reader.onload = () => {
            this.restoreWorkspace(reader.result)
        };
    };

    clickFileInputField = () => {
        document.getElementById('inputFileUpload').click();
    };

    sendCode = () => {
        const tempCode = this.code;
        evaluateCode(tempCode);
    };

    componentDidMount() {
        Blockly.JavaScript.INFINITE_LOOP_TRAP = 'if(--loopTrap == 0) throw "Infinite loop.";\n';
        this.code = Blockly.JavaScript.workspaceToCode(BlocklyDrawer.workspaceXML);
    }

    render() {
        if (this.props.tab === undefined || this.props.tabs[this.props.tab].isActive) {
            return (
                <div id="blockly-container">
                    <BlocklyDrawer
                        className="BlocklyDrawer"
                        style={{
                            height: '100%',
                            top: '0px',
                            left: '0px'
                        }}
                        tools={[
                            blocks.shoot,
                            blocks.reset,
                            // blocks.reload,
                            blocks.turnHolder,
                            blocks.turnElastic,
                            blocks.draw,
                            blocks.halfCircle,
                            blocks.movePen,
                            blocks.move,
                            blocks.turn
                        ]}
                        workspaceXML={this.props.workspace}
                        onChange={(code, workspace) => {
                            this.setWorkspaceAndCode(code, workspace);
                        }}
                        appearance={{
                            categories: {
                                Catapult: {
                                    colour: '100'
                                },
                                Drawbot: {
                                    colour: '0'
                                },
                                Movement: {
                                    colour: '250'
                                },
                                Emotions: {
                                    colour: '180'
                                },
                                Number: {
                                    colour: '200'
                                }
                            },
                        }}
                    >

                        <Category name="Functions" custom="PROCEDURE" colour="290"/>

                        <Category name="Loops" colour="200">
                            <Block type="controls_whileUntil"/>
                            <Block type="controls_for"/>
                            <Block type="controls_repeat_ext"/>
                        </Category>

                        <Category name="Logic" colour="210">
                            <Block type="logic_compare"/>
                            <Block type="logic_operation"/>
                            <Block type="logic_boolean"/>
                            <Block type="controls_if"/>
                        </Category>

                        <Category name="Variables" colour="220" custom="VARIABLE"/>

                        <Category name="Number" colour="230">
                            <Block type="math_number"/>
                        </Category>

                        {/*Detected modules are mapped, then added to the list of categories in the front-end*/}
                        {this.state.modules.map((module, index) => {
                            return <Category key={module.moduleType + index} name={module.moduleType}
                                             colour={module.color}><Block type={module.moduleType}/></Category>
                        })}
                    </BlocklyDrawer>

                    <div id="btn-container">
                        <div id="showCode" className="btn-blockly-editor fa fa-code blockly-buttons"
                             onClick={this.showCode}/>
                        <div id="runCode" className="btn-blockly-editor fa fa-play-circle-o blockly-buttons"
                             onClick={this.sendCode}/>
                        <div id='downloadCode' className="btn-blockly-editor fa fa-download blockly-buttons"
                             onClick={this.downloadCode}/>
                        <input id='inputFileUpload' type={'file'} multiple={false} accept={'.xml'}
                               onChange={this.reloadCode}
                               style={{display: 'none'}}/>
                        <div id='fileUploadButton' className="blockly-buttons btn-blockly-editor fa fa-upload"
                             onClick={this.clickFileInputField}/>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default BlocklyEditor;
