import React, { Component } from "react";
import {} from "../js/websocket.js";
import "./Loading.css";
import "./Login.css";
import "../App.css";

import logo4 from "../img/logo/logo4.png";


class LoadingPage extends Component {
    render() {
        return (
            <div className='login-wrapper'>
                <div className='loginBox'>
                    <div className='login-header'>
                        <img src={logo4} id='logo' className='img' alt='logo' />
                    </div>
                    <div>
                        <i className='fa fa-gear fa-spin loading-gear'></i>
                        <p>Trying to connect to the server...</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoadingPage;
