import openSocket from 'socket.io-client';

var socketServer = process.env.REACT_APP_SOCKET_SERVER;
const socket = openSocket(socketServer, {
  'reconnection': process.env.REACT_APP_RECONNECT,
  'reconnectionDelay': process.env.REACT_APP_RECONNECTION_DELAY,
  'reconnectionDelayMax': process.env.REACT_APP_RECONNECTION_DELAY_MAX,
  'reconnectionAttempts': process.env.REACT_APP_RECONNECTION_ATTEMPTS,
});
var connectedRobotId = '';

function successfulRegistration(cb) {
  socket.on('robotSuccessfulRegistered', (robotId) => {
    console.log(robotId);
    connectedRobotId = robotId;
    cb(robotId);
  });
}

function unSuccessfulRegistration(cb) {
  socket.on('couldNotFindRobot', (robotId) => {
    console.log('could not find robot ' + robotId);
    cb(robotId);
  });
}

/** Function for catching connected sensor modules events from the Socket-Server */
function onIdentifyModule(cb) {
  socket.on('identifyModule', (json) => {
    console.log('Module connected');
    cb(json);
  });
}

/** Function for catching sensor value events from the Socket-Server */
function onSensorValue(cb) {
  socket.on('sensorValue', (json) => {
    console.log('Sensor Value Received');
    cb(json);
  });
}

function connectTimeOut() {
  socket.on('connect_timeout', () => {
    console.log('connect timeout');
  });
}

function reconnect() {
  socket.on('reconnect', (number) => {
    console.log('reconnect ' + number);
  });
}

function reconnecting() {
  socket.on('reconnecting', (number) => {
    console.log('reconnecting ' + number);
  });
}

function reconnectFailed() {
  socket.on('reconnect_failed', () => {
    console.log('reconnect_failed ');
  });
}

function connected(cb) {
  socket.on('connect', (object) => {
    console.log('connected ');
    cb(object);
  });
}

function register(robotId) {
  socket.emit('registerWebAppClient', robotId);
}

function sendEvent(event) {
  console.log(event)
  socket.emit('sendEvent', eventObject(connectedRobotId, event));
}

function eventObject(robotId, event) {
  return {
    'robotId': robotId,
    'event': event,
  };
}

function initSocket() {
  connectTimeOut();
  reconnect();
  reconnecting();
}

export {
  register,
  successfulRegistration,
  unSuccessfulRegistration,
  sendEvent,
  initSocket,
  reconnectFailed,
  connected,
  onIdentifyModule,
  onSensorValue,
};
