import Blockly from 'node-blockly/browser';
//Images
import happy from '../../img/emotions/happy.jpg';
import sad from '../../img/emotions/sad.jpg';
import fear from '../../img/emotions/fear.jpg';
import surprise from '../../img/emotions/surprise.jpg';
import anger from '../../img/emotions/anger.jpg';
import disgust from '../../img/emotions/disgust.jpg';

//Constants for movement wheels
const WHEEL_STEPS_PER_ROUND = 4096;
const WHEEL_DIAMETER_CM = 3;
const WHEEL_CIRCUMFERENCE = Math.PI * WHEEL_DIAMETER_CM;
const CM_PER_STEP = WHEEL_CIRCUMFERENCE / WHEEL_STEPS_PER_ROUND;

//Move
const move = {
    name: 'move',
    category: 'Movement',
    block: {
        init: function () {
            this.jsonInit({
                "type": "move",
                "message0": "Direction %1 %2 cm",
                "args0": [
                    {
                        "type": "field_dropdown",
                        "name": "direction",
                        "options": [
                            [
                                "forward",
                                "forward"
                            ],
                            [
                                "backward",
                                "backward"
                            ]
                        ]
                    },
                    {
                        "type": "field_number",
                        "name": "cm",
                        "value": 10,
                        "min": 0.0023,
                        "max": 100
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": 250,
                "tooltip": "Move the robot forward or backward",
                "helpUrl": ""
            });
        },
    },
    generator: (block) => {
        let dropdown_direction = block.getFieldValue('direction');
        let move_cm = block.getFieldValue('cm');

        let number_steps = Math.round(Math.pow((CM_PER_STEP / move_cm), (-1)));

        return '\nmoveDirection("' + dropdown_direction + '",' + number_steps + ');';
    },
};
//Draw
const draw = {
    name: 'startDrawing',
    category: 'Drawbot',
    block: {
        init: function () {
            this.jsonInit({
                    "type": "startDrawing",
                    "message0": "Rotate both motors %1 cm",
                    "args0": [
                        {
                            "type": "field_number",
                            "name": "target",
                            "value": 6,
                            "min": 0.0023,
                            "max": 100
                        }
                    ],
                    "previousStatement": null,
                    "nextStatement": null,
                    "colour": 0,
                    "tooltip": "start the steppers to draw",
                    "helpUrl": ""
                }
            );
        },
    },
    generator: (block) => {
        let move_cm = block.getFieldValue('target');

        let number_steps = Math.round(Math.pow((CM_PER_STEP / move_cm), (-1)));
        return '\nengin("startDrawing",' + number_steps + ');';
    },
};


//half circle formaat
const halfCircle = {
    name: 'StartHalfCircle',
    category: 'Drawbot',
    block: {
        init: function () {
            this.jsonInit({
                    "type": "StartHalfCircle",
                    "message0": "Rotate paper %1 cm",
                    "args0": [
                        {
                            "type": "field_number",
                            "name": "target",
                            "value": 6,
                            "min": 0.0023,
                            "max": 100
                        }
                    ],
                    "previousStatement": null,
                    "nextStatement": null,
                    "colour": 0,
                    "tooltip": "start the steppers to draw",
                    "helpUrl": ""
                }
            );
        },
    },
    generator: (block) => {
        let move_cm = block.getFieldValue('target');

        let number_steps = Math.round(Math.pow((CM_PER_STEP / move_cm), (-1)));
        return '\nhalfCircle("StartHalfCircle",' + number_steps + ');';
    },
};

const movePen = {
    name: 'movePen',
    category: 'Drawbot',
    block: {
        init: function () {
            this.jsonInit({
                "type": "movePen",
                "message0": "Move pen %2 cm to the %1",
                "args0": [
                    {
                        "type": "field_dropdown",
                        "name": "direction",
                        "options": [
                            [
                                "right",
                                "moveRight"
                            ],
                            [
                                "left",
                                "moveLeft"
                            ]
                        ]
                    },
                    {
                        "type": "field_number",
                        "name": "cm",
                        "value": 6,
                        "min": 0.0023,
                        "max": 100
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": 0,
                "tooltip": "Move the robot forward or backward",
                "helpUrl": ""
            });
        },
    },
    generator: (block) => {
        let dropdown_direction = block.getFieldValue('direction');
        let move_cm = block.getFieldValue('cm');

        let number_steps = Math.round(Math.pow((CM_PER_STEP / move_cm), (-1)));

        return '\nmoveDirection("' + dropdown_direction + '",' + number_steps + ');';
    },
};


//Shoot
const shoot = {
    name: 'shoot',
    category: 'Catapult',
    block: {
        init: function () {
            this.jsonInit({
                "type": "shoot",
                "message0": "Shoot",
                "previousStatement": null,
                "nextStatement": null,
                "colour": 100,
                "tooltip": "Shoot the catapult",
                "helpUrl": ""
            });
        },
    },
    generator: (block) => {
        return '\ncatapult("shoot");';
    },
};


//Reload
const reload = {
    name: 'reload',
    category: 'Catapult',
    block: {
        init: function () {
            this.jsonInit({
                "type": "reload",
                "message0": "Reload",
                "previousStatement": null,
                "nextStatement": null,
                "colour": 100,
                "tooltip": "Reload the catapult",
                "helpUrl": ""
            });
        },
    },
    generator: (block) => {
        return '\ncatapult("reloadCatapult");';
    },
};

//Reload
const reset = {
    name: 'reset',
    category: 'Catapult',
    block: {
        init: function () {
            this.jsonInit({
                "type": "reset",
                "message0": "Reset servos",
                "previousStatement": null,
                "nextStatement": null,
                "colour": 100,
                "tooltip": "Reset the catapult to original positions",
                "helpUrl": ""
            });
        },
    },
    generator: (block) => {
        return '\ncatapult("resetCatapult");';
    },
};

//turnServo
const turnElastic = {
    name: 'turnElastic',
    category: 'Catapult',
    block: {
        init: function () {
            this.jsonInit({
                "type": "turnElastic",
                "message0": "Turn elastic servo to %1 degrees",
                "args0": [
                    {
                        "type": "field_number",
                        "name": "degrees_elastic",
                        "value": 90,
                        "min": 0,
                        "max": 180,
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": 100,
                "tooltip": "Move the robot forward or backward",
                "helpUrl": ""
            });
        },
    },
    generator: (block) => {
        let degrees = block.getFieldValue('degrees_elastic');
        return '\ncatapult("turnElastic",' + degrees + ');';
    },
};

//turnServo
const turnHolder = {
    name: 'turnHolder',
    category: 'Catapult',
    block: {
        init: function () {
            this.jsonInit({
                "type": "turnHolder",
                "message0": "Turn holder servo to %1 degrees",
                "args0": [
                    {
                        "type": "field_number",
                        "name": "degrees_holder",
                        "value": 90,
                        "min": 0,
                        "max": 180,
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": 100,
                "tooltip": "Move the robot forward or backward",
                "helpUrl": ""
            });
        },
    },
    generator: (block) => {
        let degrees = block.getFieldValue('degrees_holder');
        return '\ncatapult("turnHolder",' + degrees + ');';
    },
};


//////////////LDR/////////////////
const ldr =
    Blockly.Blocks['LDR'] = {
        moduleType: 'LDR',
        type: "LDR",
        category: 'LDR',
        init: function () {
            this.jsonInit({
                "message0": 'LDR value %1',
                "args0": [
                    {
                        "type": "input_value",
                        "name": "VALUE",
                    }
                ],
                "output": "Number",
                "colour": 160,
                "tooltip": "Use the LDR sensor",
                "helpUrl": "https://wikipedia.org/wiki/Photoresistor"
            });
        }
    };

//The code generated for the ldr
Blockly.JavaScript['LDR'] = function (block) {
    //The ldrValue which gets read from the sensor
    const sensorType = block.type
    const code = 'await getValue("' + sensorType + '")';
    return [code, Blockly.JavaScript.ORDER_AWAIT];
}

const turn = {
    name: 'turn',
    category: 'Movement',
    block: {
        init: function () {
            this.jsonInit({
                "type": "turn",
                "message0": "Direction %1 Degree %2",
                "args0": [
                    {
                        "type": "field_dropdown",
                        "name": "DIRECTION",
                        "options": [
                            [
                                "right",
                                "turnRight"
                            ],
                            [
                                "left",
                                "turnLeft"
                            ]
                        ]
                    },
                    {
                        "type": "field_angle",
                        "name": "DEGREES",
                        "angle": 90
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": 250,
                "tooltip": "Make the robot turn",
                "helpUrl": ""
            });
        },
    },
    generator: (block) => {
        const dropdown_direction = block.getFieldValue('DIRECTION');
        const angle_degrees = block.getFieldValue('DEGREES');
        return '\nturnDirection("' + dropdown_direction + '",' + angle_degrees + ');';
    },
};

const number = {
    name: 'number',
    category: "Number",
    block: {
        init: function () {
            this.jsonInit({
                "type": "math_number",
                "message0": "%1",
                "args0": [{
                    "type": "field_number",
                    "name": "NUM",
                    "value": 0,
                    "max": 99,
                }],
                "colour": 232,
                "output": "Number",
                "helpUrl": "%{BKY_MATH_NUMBER_HELPURL}",
                "style": "math_blocks",
                "tooltip": "%{BKY_MATH_NUMBER_TOOLTIP}",
                "extensions": ["parent_tooltip_when_inline"]
            })
        }
    },
    generator: (block) => {
        // Numeric value.
        const code = parseFloat(block.getFieldValue('NUM'));
        const order = code >= 0 ? Blockly.JavaScript.ORDER_ATOMIC :
            Blockly.JavaScript.ORDER_UNARY_NEGATION;
        return [code, order];
    }
}

//EMOTIONS/////////////////////
const face = {
    name: 'face',
    category: 'Emotions',
    block: {
        init: function () {
            this.jsonInit({
                "type": "face",
                "message0": "Select Smiley %1 %2",
                "args0": [
                    {
                        "type": "input_dummy"
                    },
                    {
                        "type": "field_dropdown",
                        "name": "EMOTION",
                        "options": [
                            [
                                {
                                    "src": happy,
                                    "width": 40,
                                    "height": 40,
                                    "alt": "happy"
                                },
                                "0"
                            ],
                            [
                                {
                                    "src": sad,
                                    "width": 40,
                                    "height": 40,
                                    "alt": "sad"
                                },
                                "1"
                            ],
                            [
                                {
                                    "src": fear,
                                    "width": 40,
                                    "height": 40,
                                    "alt": "fear"
                                },
                                "2"
                            ],
                            [
                                {
                                    "src": surprise,
                                    "width": 40,
                                    "height": 40,
                                    "alt": "surprise"
                                },
                                "3"
                            ],
                            [
                                {
                                    "src": anger,
                                    "width": 40,
                                    "height": 40,
                                    "alt": "anger"
                                },
                                "4"
                            ],
                            [
                                {
                                    "src": disgust,
                                    "width": 40,
                                    "height": 40,
                                    "alt": "disgust"
                                },
                                "5"
                            ]
                        ]
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": 260,
                "tooltip": "",
                "helpUrl": ""
            });
        },
    },
    generator: (block) => {
        const dropdown_emotion = block.getFieldValue('EMOTION');
        return '\nshowSmiley("showSmiley",' + dropdown_emotion + ');';
    },

};

//LED/////////////////////////////
const led = {
    name: 'led',
    category: 'LED',
    block: {
        init: function () {
            this.jsonInit({
                "type": "block_type",
                "message0": "Flash interval %1",
                "args0": [
                    {
                        "type": "field_number",
                        "name": "FLASH_SPEED",
                        "value": 1000,
                        "min": 100,
                        "max": 10000
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": 230,
                "tooltip": "Makes the onboard LED blink",
                "helpUrl": ""
            });
        },
    },
    generator: (block) => {
        const number_flash_speed = block.getFieldValue('FLASH_SPEED');
        return 'var toggle=1;setInterval(function() {toggle=!toggle;digitalWrite(D2, toggle);},' + number_flash_speed + ');\n';
    },
};

const clearInterval = {
    name: 'clearInterval',
    category: 'LED',
    block: {
        init: function () {
            this.jsonInit({
                "type": "block_type",
                "message0": "Clear interval",
                "colour": 230,
                "tooltip": "Clears the interval for the blinking LED block",
                "helpUrl": ""
            });
        },
    },
    generator: (block) => {
        return 'clearInterval();\n';
    },
};

//Example/////////////////////
const example = {
    name: 'exampleSensor',
    category: 'Sensors',
    block: {
        init: function () {
            this.jsonInit({
                "type": "sensor",
                "message0": "Select Sensor %1 %2",
                "args0": [
                    {
                        "type": "input_dummy"
                    },
                    {
                        "type": "field_dropdown",
                        "name": "SENSOR",
                        "options": [
                            [
                                {
                                    "name": "SENSOR",
                                    "width": 40,
                                    "height": 40,
                                    "alt": "Sensor"
                                },
                                "0"
                            ],
                        ]
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": 40,
                "tooltip": "",
                "helpUrl": ""
            });
        },
    },
    generator: (block) => {
        const dropdown_sensor = block.getFieldValue('SENSOR');
        return '\nexampleSensor("exampleSensor",' + dropdown_sensor + ');';
    },
};

////////Move servo//////////////
const moveServo = {
    name: 'moveServo',
    category: 'Servo',
    block: {
        init: function () {
            this.jsonInit({
                "type": "moveServo",
                "message0": "From position %1 to position %2",
                "args0": [
                    {
                        "type": "field_dropdown",
                        "name": "START",
                        "options": [
                            [
                                "0",
                                "0"
                            ],
                            [
                                "45",
                                "45"
                            ],
                            [
                                "90",
                                "90"
                            ],
                            [
                                "135",
                                "135"
                            ],
                            [
                                "180",
                                "180"
                            ]
                        ]
                    },
                    {
                        "type": "field_dropdown",
                        "name": "STOP",
                        "options": [
                            [
                                "0",
                                "0"
                            ],
                            [
                                "45",
                                "45"
                            ],
                            [
                                "90",
                                "90"
                            ],
                            [
                                "135",
                                "135"
                            ],
                            [
                                "180",
                                "180"
                            ]
                        ]
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": 16,
                "tooltip": "Make the servo turn",
                "helpUrl": ""
            });
        },
    },
    generator: (block) => {
        const from_pos = block.getFieldValue('START');
        const to_pos = block.getFieldValue('STOP');
        return `
        for(angle = ${from_pos}; angle < ${to_pos}; angle++) {
                servo.write(angle);
                delay(15);
        }`;
    },
};

//Put all the variables from blocks into an array and export it.
//That way, we can use the variables in other files like BlocklyEditor.
let blocks = {
    turn,
    move,
    shoot,
    reload,
    reset,
    draw,
    halfCircle,
    movePen,
    turnHolder,
    turnElastic,
    face,
    moveServo,
    number,
    ldr
};

export {blocks}
