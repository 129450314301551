import React, { Component } from 'react';
import {  } from "../js/websocket.js"
import './Login.css';
import './Error.css';
import '../App.css';

import logo4 from "../img/logo/logo4.png";

/*const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});*/

class ErrorPage extends Component {
    render() {
        return (
            <div className='login-wrapper'>
                <div className='loginBox'>
                    <div className='login-header'>
                        <img src={logo4} id='logo' className='img' alt='logo' />
                    </div>
                    <div>
                        <i className='fa fa-times-circle failed-icon'></i>
                        <p>{this.props.errormessage}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorPage;
