import {onSensorValue, sendEvent} from './websocket';

let worker;

try {
  worker = new Worker('./worker.js');
} catch (e) {
  console.log(e)
}

onSensorValue(json => {
  worker.postMessage(json)
})

async function evaluateCode (code) {
  console.log("eval: " + code);
  try {
    worker.postMessage({ code: code });
    worker.onmessage = (e) => {
      sendEvent(e.data);
    };
  } catch (e) {
    console.log(e);
  }
}

export {
  evaluateCode,
};
